body {
  margin: 0;
  font-family: "PTSansNarrow";
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #0a2f58;

  /* Material Form Elements styles */
  // .MuiInputLabel-root,
  // .MuiOutlinedInput-input {
  //   color: white;
  //   font-family: "LilitaOne" !important;
  // }
  // .MuiOutlinedInput-notchedOutline {
  //   border-color: white;
  // }
  /* ------------------------- */

  /* Player component styles */
  .video-react-video {
    width: 100%;
    height: 100%;
  }
  .video-react-control-bar,
  .video-react-big-play-button,
  .video-react-user-seeking {
    display: none;
  }
  /* ------------------------- */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ComponentSection {
  padding: 200px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  position: relative;

  .SectionTitle {
    z-index: 1;
    display: flex;
    align-items: center;
    width: max-content;
    background-image: (url("./assets/img/flag.png"));
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 20px 100px 60px;
    margin-bottom: 90px;
    .Icon {
      height: 70px;
    }
    h1 {
      margin-left: 20px;
      text-align: center;
      text-shadow: 0px 5px 0px black;
      stroke: black;
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: black;
    }
  }

  h1 {
    text-align: center;
  }
}

.ComponentBg {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.CustomBtn {
  background-image: url("./assets/img/sıgnup_button.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  box-shadow: unset !important;
  background-color: unset !important;
  color: white;
  font-family: "PTSansNarrow" !important;
}

.CustomInput {
  background-image: url("./assets/img/input-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: unset !important;
  border: none !important;
  outline: none !important;
  padding: 0 15px;
  font-family: unset;
  font-size: 1rem;
}

@font-face {
  font-family: "PTSansNarrow";
  src: url("./fonts/PTSansNarrow-Bold.ttf");
}

@keyframes initialAnimation {
  from {
    opacity: 0;
    bottom: -100px;
  }
  to {
    opacity: 1;
    bottom: 0;
  }
}

@media screen and (max-width: 600px) {
  body{
    font-size: 1rem;
  }
  .ComponentSection {
    padding: 100px 0;

    .SectionTitle {
      padding: 10px 80px 45px !important;
      margin-bottom: 40px;
      flex-direction: column;
      h1{
        margin: 0;
        margin-top: 5px;
      }
    }
  }
}
