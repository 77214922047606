.Allocation {
  // background-color: white;
  background: #0a2f58;

  &.visible_true {
    .Container {
      animation: initialAnimation 1s ease-out;
      opacity: 1;
    }
  }

  .Container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 445px;
    max-width: 1064px;
    margin: auto;
    position: relative;
    opacity: 0;

    .Registered{
      max-width: 400px;
      padding: 20px;
    }

    .Form {
      display: flex;
      flex-direction: column;
      width: 300px;
      margin-right: 50px;
      .Input {
        margin-bottom: 10px;
        height: 50px;
      }
      .SendBtn {
        height: 50px;

        &.Disabled-true {
          opacity: 0.4;
        }
      }
      .TextArea {
        margin-bottom: 10px;
        height: 100px;
        padding: 15px;
      }
      .WarningMessage{
        font-size: 0.8rem;
        color: #c6cbde;
      }
    }

    .Card {
      font-size: 1rem;
      width: 510px;
      height: 300px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: white;
      background-image: url("../../assets/img/card-bg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: relative;

      .GlowEffect {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      h3 {
        margin: 0;
        margin-top: 5px;
      }

      .Right {
        text-align: end;
      }

      span {
        color: #9b9b9b;
      }

      .Top {
        display: flex;
        justify-content: center;
        .Text {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          span {
            margin-right: 10px;
          }
          h3 {
            margin-top: 0;
          }
        }
      }
      .Middle {
        .Round {
          display: flex;
          justify-content: space-between;
        }
        .ProgressBar {
          margin-top: 20px;

          .BarContainer {
            width: 100%;
            height: 40px;
            position: relative;
            background-image: url("../../assets/img/card-bar-bg.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;

            .Bar {
              max-width: 100%;
              height: 100%;
              background-image: url("../../assets/img/card-bar.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              font-size: 0.8rem;
              color: white;

              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: white;
              }
            }
          }
        }

        .ProgressSpan {
          margin-top: 7px;
          display: flex;
          justify-content: space-between;
        }
      }
      .Bottom {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (max-width: 992px) {
    .Container {
      height: 570px;
      flex-direction: column-reverse;
      justify-content: center;
      .Form {
        margin-top: 20px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .Container {
      padding: 0 20px;
      width: calc(100% - 40px);
      margin-top: 140px;
      .Card {
        width: calc(100% - 40px) !important;
      }
      .Form {
        margin-right: 0 !important;
      }
    }
  }
}
