.About {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61d7f5bb1e7c2f002d4bdb57?alt=media&timestamp=1641543099944");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 1100px;
  margin-top: 80px;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(10 47 88 / 30%);
  }

  .VideoContainer{
    position: relative;
    .IntroVideo {
      box-shadow: 0px 3px 6px 2px #0a2f58;
      border-radius: 10px;
    }
  }

  &.visible_true {
    .Content {
      .Container {
        h1 {
          font-size: 2.5rem;
        }
        opacity: 1;
        animation: initialAnimation 1s ease-out;
      }
    }
  }

  .Content {
    margin-top: -100px;
    z-index: 10;
    max-width: 1064px;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    padding-top: 0;

    .Container {
      max-width: 450px;
      margin-right: 200px;
      position: relative;
      opacity: 0;

      h1,
      h3 {
        text-shadow: 0px 5px 0px black;
        stroke: black;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: black;
      }

      .SocialNetworks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        a {
          margin-right: 15px;
          text-decoration: none;
        }
        .Social {
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 7px;
          border: 1px solid white;
          border-radius: 100%;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 992px) {
    .Content {
      .Container {
        margin-right: 50px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .Content {
      text-align: center;
      flex-direction: column;
      h1 {
        font-size: 2rem !important;
      }

      .SocialNetworks{
        justify-content: center;
      }
      .IntroVideo{
        margin-top: 50px !important;
      }

      .Container {
        margin-right: 0;
      }
    }
  }
}
