.Features {
  background: #0a2f58;
  position: relative;

  .Container {
    margin-top: 30px;
    max-width: 1064px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    z-index: 1;
    position: relative;

    .FeatureBox {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 1rem;
      width: 200px;
      height: 300px;
      margin: 0 15px;

      .Feature {
        h2 {
          margin-bottom: 0 !important;
        }

        .FeatureImg {
          background-image: url("../../assets/img/layer.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;

          width: 100%;
          min-height: 160px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            height: 100px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 600px) {
    .Container {
      flex-direction: column;
      .FeatureBox {
        margin-bottom: 30px;
        width: 230px;
      }
    }
  }
}
