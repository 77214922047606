.Roadmap {
  height: 2500px;
  position: relative;

  &.RoadmapAnimation-true {
    background: #0a2f58;
    z-index: 10;
    width: 100vw;

    .Content {
      .RoadMapContainer {
        .FadeContainer {
          transition: all 0.1s linear;
        }
        .HammerContainer {
          transition: all 0.1s linear;
          .Hammer {
            animation: hammerAnimation 0.6s;
            animation-iteration-count: infinite;
          }
        }
      }
    }
  }

  .Content {
    display: flex;
    justify-content: center;
    position: sticky;
    top: 150px;

    background-image: url("https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61d7f65c1e7c2f002d4bdb67?alt=media&timestamp=1641543261134");
    background-size: cover;
    background-repeat: no-repeat;

    padding: 80px 120px;
    height: 580px;
    border: 2px solid #094577;
    border-radius: 20px;
    overflow: hidden;

    .RoadMapContainer {
      width: 1064px;
      display: flex;
      position: relative;
      .HammerContainer {
        position: absolute;
        bottom: 300px;
        left: -50px;
        height: 120px;
        z-index: 9999;
        width: fit-content;
        .Hammer {
          height: 100%;
          z-index: 11;
          transform: rotate(-15deg);
          transform-origin: 85% 85%;
        }
      }

      .PlanContainer {
        width: 100%;
        position: relative;

        .Line {
          width: 100%;
          height: 2px;
          background: #1170bd;
          position: absolute;
          top: 50%;
          left: 50%;
          margin-top: 0px;
          z-index: 1;
          transform: translate(-50%, 50%);
        }

        .DotsContainer {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;

          .Dot {
            width: 26px;
            height: 26px;
            border-radius: 100%;
            position: absolute;
            top: 50%;
            margin-top: -11px;
            z-index: 1000;
            background: #1170bd;
            box-shadow: 0px 0px 2px 1px #1976d2;

            &.Dot-1,
            &.Dot-2,
            &.Dot-3,
            &.Dot-4 {
              &::after {
                content: "";
                position: absolute;
                height: 170px;
                width: 2px;
                left: 12px;
                background: #1170bd;
                box-shadow: 0px 0px 2px 0px #1976d2;
              }
            }
            &.Dot-1 {
              &::after {
                top: 27px;
                left: 12px;
              }
            }
            &.Dot-2 {
              left: 225px;
              &::after {
                top: -171px;
              }
            }
            &.Dot-3 {
              left: 500px;
              &::after {
                top: 27px;
              }
            }
            &.Dot-4 {
              left: 750px;
              &::after {
                top: -171px;
              }
            }
          }
        }

        .Plan {
          width: calc(100% - 35px);
          min-width: 1020px;
          position: relative;
          display: flex;
          justify-content: space-between;
          margin-left: 35px;

          .Left,
          .Right {
            position: relative;
            min-width: 455px !important;
          }

          &.Top {
            .Left {
              margin-left: 225px;
            }
            .Right {
              margin-left: 75px;
            }
          }
          &.Bottom {
            margin-top: 60px;
            .Right {
              margin-right: 73px;
            }
          }
        }
      }
      .FadeContainer{
        position: absolute;
        top: -80px;
        width: 150%;
        height: calc(100% + 160px);
        background-color: #0a2f58;
        z-index: 1000;
        &:after{
          content: "";
          position: absolute;
          left: -100px;
          top: 0px;
          height: 100%;
          width: 100px;
          background: linear-gradient(to right, #09457700, #0a2f58);
        }
      }
    }
  }

  @keyframes hammerAnimation {
    from {
      transform: rotate(-65deg);
    }
    to {
      transform: rotate(-15deg);
    }
  }

  @media screen and (max-width: 992px) {
  }

  @media screen and (max-width: 600px) {
    height: unset;
    margin: 0 20px;

    &.RoadmapAnimation-true {
      width: unset;
    }

    .Content {
      height: max-content;
      width: calc(100% - 40px);
      padding: 30px 15px;
      .RoadMapContainer {
        width: 100%;
        flex-direction: column;

        .PlanContainer {
          display: flex;
          // flex-direction: column;
          flex-direction: column-reverse;
          .Plan {
            width: 100%;
            flex-direction: column !important;
            min-width: unset !important;
            margin-left: unset !important;
            margin-top: unset !important;

            .Left,
            .Right {
              min-width: unset !important;
              margin-left: unset !important;
              margin-right: unset !important;
              margin-top: unset !important;
            }
          }
        }

        .FadeContainer{
          display: none !important;
        }
        .HammerContainer {
          display: none;
        }
        .Dot {
          display: none;
        }
        .Line {
          display: none;
        }
      }
    }
  }
}
