.PlayToEarn {
  background: linear-gradient(0deg, #0a2f58, #0d5e9e);

  &.visible_true {
    .Content {
      animation: initialAnimation 1s ease-out;
      opacity: 1;
    }
  }

  .Content {
    margin-top: 30px;
    max-width: 1064px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;

    .ImgBox {
      width: 25%;
      img {
        width: 100%;
        border-radius: 10px;
      }
    }

    .Text {
      width: 45%;
    }
  }
  
  @media screen and (max-width: 600px) {
    .Content {
      padding: 0 20px;
      flex-direction: column;
      .ImgBox {
        margin-top: 25px;
        width: 85%;
      }
      .Text {
        text-align: center;
        width: 100%;
      }
    }
  }
}
