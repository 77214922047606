.Rounds {
  height: 2500px;
  position: relative;
  &.visible_true {
    .ComponentBg {
      transition: all 0.1s linear;
    }

    .Container {
      .CardsContainer {
        .Card {
          animation: initialAnimation 1s ease-out;
          opacity: 1;
          @for $i from 1 through 2 {
            &:nth-of-type(#{$i}) {
              animation-delay: #{$i/10}s;
              transition-delay: #{$i/10}s;
            }
          }
        }
      }
    }
  }

  &.RoundsAnimation-true {
    .Container {
      transition: all 0.1s linear;
    }
  }

  .Container {
    width: 100%;
    height: 100vh;
    position: sticky;
    top: 150px;
    overflow: hidden;

    .CardsContainer {
      color: white;
      margin-top: 120px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      z-index: 11;

      .Card {
        opacity: 0;
        display: inline-block;
        margin: 0 15px;
        width: 280px;
        height: 480px;
        text-align: center;
        position: relative;
        overflow: hidden;
        background-image: url("../../assets/img/popup_signup.png");
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 20px;

        h3 {
          margin: 0;
          margin-top: 35px;
        }
        .Content {
          padding: 0 20px;
          h1 {
            margin: 24px;
            margin-top: 80px;
          }
          p {
            font-size: 1rem;
          }
        }

        .RegisterBtn {
          position: absolute;
          margin-top: 15px;
          bottom: 0;
          transform: translate(-50%, -50%);
          width: 200px;
          height: 50px;

          &.Mui-disabled {
            opacity: 0.3;
          }

          .RegisterBtnLink {
            color: inherit;
            text-decoration: none;
          }
        }
      }
    }
  }
  @media screen and (max-width: 600px) {
    height: max-content;
    padding-bottom: 50px;
    .Container{
      height: unset;
    }
    .CardsContainer{
      margin-top: unset !important;
    }
  }
}
