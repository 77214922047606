.Faucet {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url("https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61f78b73c72c96002e24f5a4?alt=media&timestamp=1643613044694");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgb(10 47 88 / 60%);
  }

  .Container {
    width: 300px;
    box-shadow: 1px 2px 3px 1px #a6d3ff;
    border-radius: 10px;
    padding: 20px;
    z-index: 1;
    background: rgb(10 47 88 / 70%);
    .Top {
      width: 100%;
      display: flex;
      justify-content: center;
      .Logo {
        height: 150px;
      }
    }
    .Middle {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      
      h2 {
        text-align: center;
        color: white;
        text-shadow: 0px 5px 0px black;
        stroke: black;
        -webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: black;
      }

      .Input {
        margin-bottom: 20px;
        height: 50px;
      }
      .SendBtn {
        height: 50px;

        &.Disabled-true {
          opacity: 0.7
        }
      }
    }
  }
}
