.Footer {
  display: flex;
  justify-content: center;
  background-image: url("https://storage.googleapis.com/download/storage/v1/b/hq-whale-islands-a8b32/o/61d7f5e01e7c2f002d4bdb5b?alt=media&timestamp=1641543137117");
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .Content {
    display: flex;
    align-items: center;
    position: relative;
    top: -130px;

    .Left {
      display: flex;
      flex-direction: column;
      align-items: center;
      .Logo {
        height: 170px;
        img {
          height: 100%;
        }
      }
      .SocialNetworks {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        position: relative;
        a {
          margin-right: 15px;
          text-decoration: none;
        }
        .Social {
          color: white;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 7px;
          border: 1px solid white;
          border-radius: 100%;
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .NavButtons{
      display: flex;
      flex-direction: row;
      .Middle {
        display: flex;
        flex-direction: column;
        margin-left: 40px;
        .FooterItem {
          font-weight: bold;
          padding: 10px 15px;
          color: white;
          text-decoration: none;
        }
      }
    }
   
    .AvalancheLogo {
      height: 200px;
      img {
        height: 100%;
      }
    }
  }

  @media screen and (max-width: 600px) {
    height: unset;
    padding-bottom: 50px;
    .Content {
      flex-direction: column;
      top: 0;

      .NavButtons{
        width: 100%;
        margin-top: 30px;
      }
      .Middle{
        margin-left: 0 !important;
        text-align: center;
        flex: 1;
      }
      .AvalancheLogo{
        height: 150px;
      }
    }
  }
}
