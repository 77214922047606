.Navbar {
  position: fixed;
  top: 0px;
  background: #0a2f58;
  width: 100%;
  height: 100px;
  z-index: 124;
  max-width: 100vw;
  .Container {
    margin: 25px 0;
    padding: unset !important;

    .Logo {
      height: 60px;
      position: fixed;
      margin-top: 280px;
      margin-left: -300px;
      transform: scale(3.8);
      transition: all 0.5s cubic-bezier(0.77,0,0.16,1.27);

      &.LogoAnimation-true {
        transform: scale(1);
      
        margin-top: 0px;
        margin-left: -460px;
      }
    }
    .NavbarItem {
      padding: 0px 15px;
      color: white;
      text-decoration: none;

      &.LaunchBtn {
        background-image: url("../../assets/img/launch-btn.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 120px;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 50px;
      }
    }

    .NavbarMenu {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      .MenuIcon {
        color: white;
        display: none;
        margin-left: 10px;
      }
    }

    .SideMenu {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      overflow: hidden;
      height: 2000px;
      background: #0a2f58;
      position: absolute;
      left: -350px;
      width: 80%;
      max-width: 350px;
      opacity: 0;
      transition: all 0.5s cubic-bezier(0.17, 0.67, 0.59, 0.87);

      .NavbarItem {
        padding: 15px;
        text-align: end;
        width: calc(100% - 30px);
        border-bottom: 1px solid rgba($color: #fff, $alpha: 0.3);
        transition: all 0.5s cubic-bezier(0.17, 0.67, 0.59, 0.87);
      }

      &.IsActive {
        opacity: 1;
        left: 0;

        .NavbarItem {
          opacity: 1;
        }
      }
    }
  }

  .SplashScreen {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background: #0a2f58;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 50%;
    }
  }

  @media screen and (max-width: 600px) {
    .Container {
      .NavbarMenu {
        justify-content: space-between;
      }
      .MenuIcon {
        display: block !important;
      }
    }
  }
}
