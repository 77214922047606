.Game {
  overflow: hidden;
  &.visible_true {
    .Container {
      animation: initialAnimation 1s ease-out;
      opacity: 1;
    }
  }

  .VideoContainer {
    width: 100%;
    max-height: 847px;
    .BgGameVideo {
      position: absolute;
      top: 0;
      z-index: -1;
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(
        0deg,
        rgba(10, 47, 88, 1) 5%,
        rgba(10, 46, 88, 0) 45%,
        rgba(10, 47, 88, 0) 45%,
        rgba(10, 47, 88, 1) 95%
      );
    }
  }

  .Container {
    opacity: 0;
    margin-top: 30px;
    max-width: 1064px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    z-index: 1;

    .ImgBox {
      width: 30%;
      img {
        width: 100%;
        border-radius: 10px;
        box-shadow: -1px 2px 6px 1px #6a6a6a8f;
      }
    }

  }

  @media screen and (max-width: 600px) {
    .BgGameVideo {
      display: none;
    }
    .Container {
      padding: 0 20px;
      flex-direction: column;
      .ImgBox {
        width: 100%;
      }
      .Text {
        text-align: center;
        width: 100%;
        margin-top: 25px;
      }
    }
  }
}
